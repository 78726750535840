<template>
  <v-row justify="center" class="mx-0 my-4">
    <v-col cols="12" md="8">
      <RouterView :page="page"/>
    </v-col>
  </v-row>
</template>

<script>
import { get } from '@/api/pages.js';

export default {
  name: 'PageLayout',
  data: () => ({
    page: {},
    currentSlug: '',
  }),
  created() {
    this.getPage();
  },
  watch: {
    '$route.params'() {
      if (this.currentSlug && this.currentSlug !== this.$route.params?.slug) {
        this.page = {};

        this.getPage();
      }
    },
  },
  methods: {
    async getPage() {
      this.$hero.$emit('setTitle', '');
      this.$hero.$emit('setBreadcrumbs', []);
      this.$hero.$emit('setCanSearch', false);
      this.currentSlug = this.$route.params?.slug;

      if (this.currentSlug) {
        const response = await get(this.currentSlug);
        this.page = response.data;

        this.setBreadCrumbs();
      }
    },
    setBreadCrumbs() {
      this.$hero.$emit('setTitle', this.page.title || '');
      this.$hero.$emit('setBreadcrumbs', [
        {
          text: 'home',
          exact: true,
          to: { name: 'home' },
        },
        {
          text: (this.page.title || '').toLowerCase(),
          to: {
            name: 'page-view.details',
            params: { slug: this.page.slug },
          },
        },
      ]);
    },
  },
};
</script>
